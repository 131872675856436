import App from "@core/App";
import { ApiProvider } from "@core/contexts/ApiContext";
import { ConfigProvider } from "@core/contexts/ConfigContext";
import Api from "@core/lib/api";
import { standalone as log } from "@core/lib/logger";
import { enableMocking } from "@core/lib/utils";
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import EEOQuestionsWidget from "./components/EEOQuestionsWidget";
import UploadWidget from "./components/UploadWidget";

log("startup", import.meta.env);

const token = new URLSearchParams(window.location.search).get("token");
const api = Api.fromLocalStorage(token, { deploymentContext: "standalone" });

const router = createBrowserRouter([
  {
    path: "/upload/resume/:uploadToken",
    element: <UploadWidget />,
  },
  {
    path: "/upload/eeoc/:uploadToken",
    element: <EEOQuestionsWidget />,
  },
  {
    path: "*",
    element: <App isOpen />,
  },
]);

window.addEventListener("DOMContentLoaded", () => {
  const styleSheet = document.styleSheets[0];

  enableMocking().then(() => {
    const root = createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <ApiProvider api={api}>
          <ConfigProvider styleSheet={styleSheet}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </ApiProvider>
      </React.StrictMode>,
    );
  });
});
