import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { uuidv7 } from "uuidv7";

import { createSubscription } from "../lib/cable";
import { useApi } from "./ApiContext";

export const ChatContext = createContext();

function chatReducer(state, action) {
  switch (action.type) {
    case "set_messages":
      return {
        ...state,
        messages: action.payload,
      };
    case "add_message":
      if (
        state.messages.some((message) => message.uuid === action.payload.uuid)
      ) {
        return state;
      }
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    case "set_typing":
      return {
        ...state,
        typing: action.payload,
      };
    case "set_pending":
      return {
        ...state,
        pending: action.payload,
      };
    case "set_status":
      return {
        ...state,
        status: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function ChatProvider({ children }) {
  const { api, session } = useApi();
  const [state, dispatch] = useReducer(chatReducer, {
    messages: [],
    suggestions: [],
    typing: false,
    pending: false,
    status: "idle",
  });

  const pendingTimer = useRef(null);

  const clearPending = () => {
    clearTimeout(pendingTimer.current);
    pendingTimer.current = null;
    dispatch({ type: "set_pending", payload: false });
  };

  const setPending = () => {
    if (pendingTimer.current) {
      clearPending();
    }
    dispatch({ type: "set_pending", payload: true });
    pendingTimer.current = setTimeout(clearPending, 3500);
  };

  const setStatus = (status) =>
    dispatch({ type: "set_status", payload: status });

  const publishMessage = useCallback(
    ({ uuid, body, role, suggestions, meta, meta_error }) => {
      dispatch({
        type: "add_message",
        payload: {
          uuid,
          body,
          role,
          suggestions,
          meta,
          meta_error,
        },
      });
      if (role === "bot") {
        clearPending();
      }
    },
    [],
  );

  const publishStatus = (data) => {
    if (data.typing !== undefined) {
      dispatch({ type: "set_typing", payload: data.typing });
    }
  };

  useEffect(() => {
    let subscription;
    const controller = new AbortController();

    if (session?.verified) {
      api
        .getMessages(controller.signal)
        .then(({ data }) => {
          if (data.messages?.length) {
            dispatch({
              type: "set_messages",
              payload: data.messages
                .reverse()
                .map((m) => ({ ...m, nodeRef: createRef() })),
            });
          }
        })
        .then(() => {
          subscription = createSubscription(session, {
            initialized() {
              setStatus("initialized");
            },
            rejected() {
              setStatus("rejected");
            },
            connected(_data) {
              setStatus("connected");
            },
            disconnected(_data) {
              setStatus("disconnected");
              publishStatus({ typing: false });
            },
            received(data) {
              if (data.type === "publish_message") {
                publishMessage(data);
              }
              if (data.type === "publish_status") {
                publishStatus(data);
              }
            },
          });
        })
        .catch((err) => {
          if (err.name !== "AbortError") throw err;
        });
    }

    return () => {
      controller.abort();
      subscription?.unsubscribe();
    };
  }, [api, publishMessage, session]);

  useEffect(() => {
    let interval;
    const controller = new AbortController();

    const refreshMessages = () => {
      if (session?.verified) {
        const url = document.location?.origin + document.location?.pathname;
        api.postMessage({ url }, controller.signal).then(({ data }) => {
          if (data.messages?.length) {
            dispatch({
              type: "set_messages",
              payload: data.messages
                .reverse()
                .map((m) => ({ ...m, nodeRef: createRef() })),
            });
          }
        });
      }
    };

    // Refresh messages immediately on status change
    refreshMessages();

    if (state.status === "disconnected") {
      // If disconnected, poll every 10 seconds
      interval = setInterval(() => {
        refreshMessages();
      }, 10_000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [api, session?.verified, state.status]);

  const sendMessage = (body) => {
    const uuid = uuidv7();
    dispatch({
      type: "add_message",
      payload: {
        uuid,
        body,
        role: "user",
        created_at: new Date().toISOString(),
        suggestions: null,
      },
    });
    setPending();
    api
      .postMessage({ uuid, body })
      .then(({ data: _data, status: _status }) => {
        // TODO: track message status
      })
      .catch((err) => {
        // TODO: error handling
        console.error(err);
      });
  };

  const retry = () => {
    const message = state.messages.find((m) => m.role === "user");
    sendMessage(message.body);
  };

  const suggestions = state.messages[0]?.suggestions || [];

  return (
    <ChatContext.Provider
      value={{
        messages: state.messages.sort((a, b) => a.created_at - b.created_at),
        suggestions,
        typing: state.typing && !state.pending,
        pending: state.pending,
        status: state.status,
        sendMessage,
        retry,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
}
